import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { CountryKeys } from "reactQuery/keys";

export const useGetCountries = (enabled: boolean = true): UseQueryResult<any> => {
    const queryKey = CountryKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> =>
            (await nautilusGetRequest("/api/universe/countries/")).data,
        enabled,
    });
};
