import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { OwnerTypeKeys } from "reactQuery/keys";

export const useGetOwnerTypes = (enabled: boolean = true): UseQueryResult<any> => {
    const queryKey = OwnerTypeKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> =>
            (await nautilusGetRequest("/api/universe/owner_types/")).data,
        enabled,
    });
};
