import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { timingStatusesKeys } from "reactQuery/keys";

import { PaginatedTimingStatusesList } from "./apiTypes";

export const useGetAllTimingStatuses = (): Partial<
    UseQueryResult<PaginatedTimingStatusesList>
> => {
    const queryKey = timingStatusesKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<PaginatedTimingStatusesList> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/timing_statuses`
            );
            return data;
        },
    });
};
