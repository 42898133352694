import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusAsyncExhaustPaginatedGetRequest, nautilusGetRequest } from "network";
import { indicatorKeys, nonLegacyIndicators } from "reactQuery/keys";

import { Indicator } from "./apiTypes";

export const useGetAllIndicators = (): UseQueryResult<Indicator[]> => {
    const queryKey = indicatorKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Indicator[]> =>
            await nautilusAsyncExhaustPaginatedGetRequest(
                "/api/target_reports/indicators/"
            ),
    });
};

export const useGetIndicatorById = (
    id: number,
    enabled: boolean
): UseQueryResult<Indicator> => {
    const queryKey = indicatorKeys.single(id);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Indicator> =>
            (await nautilusGetRequest(`/api/target_reports/indicators/${id}`)).data,
        enabled,
    });
};

export const useGetNonLegacyIndicators = (
    enabled: boolean = true
): UseQueryResult<any> => {
    const queryKey = nonLegacyIndicators.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> =>
            (await nautilusGetRequest("/api/target_reports/non_legacy_indicators/"))
                .data,
        enabled,
    });
};
