import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { countyKeys } from "reactQuery/keys";

export const useGetCountiesByQuery = (
    query: string,
    enabled: boolean
): UseQueryResult<any[]> => {
    const queryKey = countyKeys.search(query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any[]> =>
            (
                await nautilusGetRequest(
                    "/api/universe/counties/",
                    `?limit=100&query_text=${query}`
                )
            ).data?.results,
        enabled,
    });
};
